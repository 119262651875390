enum TipoOcorrenciaEnum     {
    InicioTrajeto = 0,
    FimTrajeto = 1,
    Parada = 2,
    Partida = 3,
    Foto = 4,
    Falta = 5,
    Conteudo = 6,
    Alerta = 7,
    PontoCapturado = 8,
    Atualizar = 9
}

export default TipoOcorrenciaEnum;