import { render, staticRenderFns } from "./vs-app.vue?vue&type=template&id=975ea90a"
import script from "./vs-app.vue?vue&type=script&lang=ts"
export * from "./vs-app.vue?vue&type=script&lang=ts"
import style0 from "../../../modules/core/assets/css/buttons.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "../../../modules/core/assets/css/margins.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "../../../modules/core/assets/css/texts.css?vue&type=style&index=2&prod&lang=css&external"
import style3 from "../../../modules/core/assets/css/transitions.css?vue&type=style&index=3&prod&lang=css&external"
import style4 from "../../../modules/core/assets/css/modifiers.css?vue&type=style&index=4&prod&lang=css&external"
import style5 from "../../../modules/core/assets/css/flex.css?vue&type=style&index=5&prod&lang=css&external"
import style6 from "../../../modules/core/assets/css/vs-conference.css?vue&type=style&index=6&prod&lang=css&external"
import style7 from "./vs-app.vue?vue&type=style&index=7&id=975ea90a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports