
import { Component, Vue } from 'vue-property-decorator';

@Component({ })
class VsApp extends Vue {
    private beforeMount(): void {
        this.resize();
        window.addEventListener('resize', this.resize);
        this.$root.$on('updateSize', this.resize);
    }

    private appHeight: number = 0;

    private resize() {
        document.documentElement.style.setProperty('--app-height', window.innerHeight + "px");
        this.appHeight = window.innerHeight;
        this.$emit('onAppResize', { width: window.innerWidth, height: window.innerHeight });
    }
}

export default VsApp;
