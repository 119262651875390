
import { Component, Prop, Vue } from 'vue-property-decorator';
import NavigationBarPage from '@/components/pages/NavigationBarPage/NavigationBarPage.vue';

@Component({})
class NavigationBarItem extends Vue {

    // Propriedades
    @Prop({ type: String, required: true })
    public readonly icon!: string;

    @Prop({ type: String, required: false, default: '38px' })
    public readonly iconSize!: string;

    @Prop({ type: String, required: true })
    public readonly pageRoute!: string;

    public navigate(): void {
        if(this.pageRoute == '/main/aulas' && !this.$store.getters['getPossuiProcessoPratico']){
            this.$store.commit('navigate', { page: 'onboardingaulas', transition: 'crossFade' });
        }
        else {
            const pai: NavigationBarPage = this.$parent as NavigationBarPage;
            pai.navigate(this.pageRoute);
        }
    }
}

export default NavigationBarItem;
