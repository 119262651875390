enum PermissionEnum {
    StorageWrite = 0,
    StorageRead = 1,
    Speech = 2,
    Sms = 3,
    Sensors = 4,
    Reminders = 5,
    Photos = 6,
    Phone = 7,
    NetworkState = 8,
    Microphone = 9,
    Media = 10,
    Maps = 11,
    LocationAlways = 12,
    LocationWhenInUse = 13,
    LaunchApp = 14, 
    Flashlight = 15,
    ContactsWrite = 16,
    ContactsRead = 17,
    Camera = 18,
    CalendarWrite = 19,
    CalendarRead = 20,
    Battery = 21,
    Vibrate = 22
}

export default PermissionEnum;