
import { Component, Vue } from 'vue-property-decorator';
import { VNode } from 'vue';
import NavigationBarItem from '@/components/pages/NavigationBarPage/NavigationBarItem.vue';
@Component({ })
class BottomNavigationBar extends Vue {
    private actualIndex: number = 0;
    private animationName: string = 'toRight';

    private checkIndex(pageRoute: string): number {
        const pages = this.$slots.default as VNode[];
        const page = pages.find(el => {
            return (el.componentInstance as NavigationBarItem).pageRoute === pageRoute;
        });
        return pages.indexOf(page!);
    }

    public navigate(page: string): void {
        const index: number = this.checkIndex(page);
        if(this.$store.getters["getHomeMenuIndex"] !== index) {
            this.$store.commit("setHomeMenuIndex", { "index": index });
            this.animationName = index > this.actualIndex ? 'toLeft' : 'toLeftReverse';
            this.actualIndex = index;
            this.$emit('navigated', page);
            this.$store.commit('replace', { page: page, transition: this.animationName, isPath: true });
        }
    }

    private mounted(): void {
        this.actualIndex = this.checkIndex(this.$route.path);
    }
}

export default BottomNavigationBar;
